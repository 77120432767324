<template>
  <div class="my-register-page">
      <div class="my-container">
        
          <div>
              <b-row>
                  <b-col>
                      <h1 >{{ $t('personaldata') }}</h1>
                  </b-col>
                  <b-col class="mt-4" style="text-align:right">
           <b-button style="width:100px" @click="$router.go(-1)" variant="outline-danger"> {{ $t('back') }} </b-button>
        </b-col>
              </b-row>
                <!-- <b-row class="mt-4">
                  <b-col sm="3">
                    <custom-button @click.native="toggle" variant="success">
                      <div class="mx-4" >
                        <img src="images/school/edit.svg" alt=""> <span> {{ $t('changepassword') }} </span> 
                      </div>
                    </custom-button>
                  </b-col>
                   <b-col sm="9"> </b-col>
                </b-row> -->
                <b-row class="mt-4">
                    <b-col sm="12" md="4">
                        <custom-label :content="Parent.inn" :label="$t('inn')"></custom-label>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="4">
                        <!-- <custom-input  disabled v-model="Parent.shortName" :label="$t('shortname')"></custom-input> -->
                        <custom-label :content="Parent.shortName" :label="$t('shortname')"></custom-label>
                    </b-col>
                    <b-col sm="12" md="8">
                        <!-- <custom-input  disabled   v-model="Parent.fullName" :label="$t('fullname')"></custom-input> -->
                        <custom-label :content="Parent.fullName" :label="$t('fullname')"></custom-label>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="4">
                        <!-- <custom-input  disabled v-model="Parent.oked" :label="$t('oked')"></custom-input> -->
                        <custom-label :content="Parent.oked" :label="$t('oked')"></custom-label>
                    </b-col>
                    <b-col sm="12" md="4">
                        <!-- <custom-input  disabled   v-model="Parent.vatCode" :label="$t('vatcode')"></custom-input> -->
                        <custom-label :content="Parent.vatCode" :label="$t('vatcode')"></custom-label>
                    </b-col>
                    <b-col sm="12" md="4">
                        <!-- <custom-input  disabled v-model="Parent.bankCode" :label="$t('bankcode')"></custom-input> -->
                        <custom-label :content="Parent.bankCode" :label="$t('bankcode')"></custom-label>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="12">
                        <!-- <custom-input  disabled v-model="Parent.address" :label="$t('address')"></custom-input> -->
                        <custom-label :content="Parent.address" :label="$t('address')"></custom-label>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="12">
                        <!-- <custom-input  disabled v-model="Parent.bank" :label="$t('bank')"></custom-input> -->
                        <custom-label :content="Parent.bank" :label="$t('bank')"></custom-label>
                    </b-col>
                </b-row>
                <b-row style="margin-top:16px">
                    <b-col sm="12" md="4">
                        <!-- <custom-input  disabled v-model="Parent.pinfl" :label="$t('pinfl')"></custom-input> -->
                        <custom-label :content="Parent.pinfl" :label="$t('pinfl')"></custom-label>
                    </b-col>
                    <b-col sm="12" md="8">
                        <!-- <custom-input  disabled   v-model="Parent.director" :label="$t('supervisor')"></custom-input> -->
                        <custom-label :content="Parent.director" :label="$t('supervisor')"></custom-label>
                    </b-col>
                </b-row>
          </div>
      </div>
      <div class="mb-4 pb-4">
        <oked />
      </div>
      <b-sidebar v-model="sidebar1" width="400px" shadow no-header right>
        <div class="bg-white h-100 d-flex align-items-center" >
          <div>
            <div>
              <span style="font-size: 30px; margin-left: 30px">
                {{ $t('changepassword') }}
              </span>
            </div>
            <div class="mt-4" style="margin-left: 30px;margin-right: 30px">
              <custom-input type="password" placeholder="******" v-model="password.oldpassword" :label="$t('oldpassword')"></custom-input>
            </div>
            <div class="mt-2" style="margin-left: 30px;margin-right: 30px">
              <custom-input type="password" placeholder="******" v-model="password.newpassword" :label="$t('newpassword')"></custom-input>
            </div>
            <div class="mt-2" style="margin-left: 30px;margin-right: 30px">
              <custom-input type="password" placeholder="******" v-model="password.confirmedpassword" :label="$t('confirmedpassword')"></custom-input>
            </div>
            <div class="mt-3" style="margin-left: 30px;margin-right: 30px">
              <custom-button variant="success" @click.native="changepassword" block>
                <b-spinner v-if="passwordLoading" small style="margin-right:8px"></b-spinner>
                 <div class="mx-4" >
                   <span> {{ $t('save') }} </span> 
                 </div>
              </custom-button>
            </div>
          </div>
          <span class="close-btn" style="position: absolute; top : 0px; right: 0px;cursor:pointer" @click="toggle"> <img src="images/design/fill-close.svg" alt=""> </span>
        </div>
      </b-sidebar>
  </div>
</template>

<script>
import { BRow,BCol,BImg,BSpinner, BSidebar,BFormCheckbox,BIconPencil,BIconCheck2Square,BFormFile,BIconPlus,BIconX } from 'bootstrap-vue'
import customInput from '../../components/elements/customInput.vue'
import customDatePicker from '../../components/elements/customDatePicker.vue'
import customSelect from '../../components/elements/customSelect.vue'
import customButtonOutline from '../../components/elements/customButtonOutline.vue'
import customButton from '../../components/elements/customButton.vue'
import customRadioButton from '../../components/elements/customRadioButton.vue'
import customBadge from '../../components/elements/customBadge.vue'
import customDialog from '../../components/elements/customDiaolg.vue'
import AccountService from '@/services/account.service'
import customLabel from '../../components/elements/customLabel.vue'
import PlusIcon from '../../components/custom-icons/PlusIcon.vue'
import vSelect from 'vue-select'
import Oked from "../oked/index.vue"
export default {
    components : { 
        Oked, 
        BRow,
        BCol,
        BImg,
        customInput,
        customDatePicker,
        customSelect,
        BIconX,
        customButtonOutline,
        BSpinner,
        customButton,PlusIcon,customRadioButton,vSelect,customDialog,customLabel, BSidebar,BFormCheckbox,BIconPencil,BIconCheck2Square,BFormFile,BIconPlus,customBadge },
    data(){
        return{
            OblastList : [],
            SearchLoading : false,
            filter : {
                docseries : '',
                docnumber : '',
                dateofbirth : '',
                identitydocumentid : 2,
                kinshipdegreeid : 0,
                ismicroterritory : false
            },
            files : {
                file : [],
                filename : '',
                url : ''
            },
            Parent : {},
            IdentityDocumentList : [],
            PermanentAdress : {},
            isPermanentAdress : true,
            isActiveAdress : false,
            RefreshEgovLoading : false,
            ActiveAdress : {},
            MfyList : [],
            StreetList  :[],
            StreetHouseList : [],
            RegionList : [],
            SaveLoading : false,
            KinShipDegreeList : [],
            IdentityDocumentListForParent : [],
            lang : '',
            sidebar1 : false,
            passwordLoading : false,
            password : {
              oldpassword : '',
              newpassword : "",
              confirmedpassword : ""
            },
            iseditmode : false,
            admtype : 0,
            FileLoading : false,
            AttachedFiles : []
        }
    },
    created(){
        this.Refresh()
        
        this.lang = localStorage.getItem('locale') || 'uz_cyrl'
    },
    methods:{
        Refresh(){
            this.SearchLoading = true
            AccountService.GetUserInfo().then(res => {
                this.Parent = res.data.contractor
                this.SearchLoading = false
            }).catch(error => {
                this.SearchLoading = false
                this.makeToast(error.response.data.error,'error')
            })
        },
        toggle(){
          this.sidebar1 = !this.sidebar1
          this.password = {
            oldpassword : "",
            newpassword : "",
            confirmedpassword : ""
          }
        },
        changepassword(){
          this.passwordLoading = true
          AccountService.ChangePassword(this.password).then(res => {
            this.passwordLoading = false
            this.makeToast(this.$t('PasswordChangedSuccess'), 'success')
          })
          .catch( error => {
            this.passwordLoading = false
            this.makeToast(error.response.data.error,'error')
          })
        },
        ChangeStreetHouse(){
            if(!!this.Parent.livestreethousename){
                this.Parent.ismultistoreyhouse = this.StreetHouseList.filter(item => item.streethousename === this.Parent.livestreethousename)[0].ismultistoreyhouse
            }
        },
        makeToast(message,type){
            var a = ""
            if(message.status == 500){
                a = message.title
            }
            if(message.status == 400){
                var errors = Object.values(message.errors)
                var a = errors.map((el,item) => (item + 1) + "."  + el ).join('\n')
            }
            else{
              a = message
            }
            this.$toast.open({
                message: a,
                type: type,
                duration: 5000,
                dismissible: true
            })
        },

    }
}
</script>

<style>

</style>